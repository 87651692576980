import { startOfDay } from "date-fns/esm/fp";

export const MULTI_FACTOR_TYPES = {
  AUTHENTICATOR: 'AUTHENTICATOR',
  EMAIL: 'EMAIL',
};

export const INITITIAL_LOADING_DATA = {
  isLoading: false,
  loadingText: '',
};

export const MULTI_FACTOR_TYPES_LABEL = {
  AUTHENTICATOR: 'Authenticator App',
  EMAIL: 'Email',
};

export const LIBRARY_FILE_TYPE = {
  FILE: 'FILE',
  FOLDER: 'FOLDER',
};

const getPercentage = (value) => +(value * 100).toFixed();

export const CUSTOMER_TYPE = {
  LEAD: 'LEAD',
  APPLICANT: 'APPLICANT',
};

export const SHEET_DETAIL_TYPE = {
  CUSTOMER: "CUSTOMER",
  PRODUCT: "PRODUCT",
  SHEET: "SHEET"
}

export const NOTES_TYPE_OPTIONS = [
  { label: 'Anfragen', value: CUSTOMER_TYPE.LEAD },
  { label: 'Bewerber', value: CUSTOMER_TYPE.APPLICANT },
];

export const calculateLeadCampaignPercentages = (data) => {
  const {
    offer_sent,
    contact_attempt,
    new: newStatus,
    appointment_agreed,
    appointment_failed,
    appointment_is_done,
    assignment,
    not_suitable,
  } = data;

  const notAssessableCount = contact_attempt + newStatus;
  const suitableCount = appointment_agreed + appointment_failed + appointment_is_done + assignment + offer_sent;
  const notSuitableCount = not_suitable;

  const totalRelevant = notAssessableCount + suitableCount + notSuitableCount;

  const notAssessablePercentage = notAssessableCount / totalRelevant || 0;
  const suitablePercentage = suitableCount / totalRelevant || 0;
  const notSuitablePercentage = notSuitableCount / totalRelevant || 0;

  return [
    { title: 'Nicht bewertbar', color: '#616161', value: getPercentage(notAssessablePercentage) },
    { title: 'Geeignet', color: '#66be0f', value: getPercentage(suitablePercentage) },
    { title: 'Rausqualifiziert', color: '#ec4d4e', value: getPercentage(notSuitablePercentage) },
  ].filter(({ value }) => value > 0);
};

export const calculateApplicantCampaignPercentages = (data) => {
  const { application_received, contact_attempt, hired, interview, not_suitable, offer, phone_interview, screening } =
    data;

  const notAssessableCount = application_received + contact_attempt;
  const suitableCount = phone_interview + interview + screening + offer + hired;
  const notSuitableCount = not_suitable;

  const totalRelevant = notAssessableCount + suitableCount + notSuitableCount;

  const notAssessablePercentage = notAssessableCount / totalRelevant || 0;
  const suitablePercentage = suitableCount / totalRelevant || 0;
  const notSuitablePercentage = notSuitableCount / totalRelevant || 0;
  return [
    { title: 'Nicht bewertbar', color: '#616161', value: getPercentage(notAssessablePercentage) },
    { title: 'Geeignet', color: '#66be0f', value: getPercentage(suitablePercentage) },
    { title: 'Rausqualifiziert', color: '#ec4d4e', value: getPercentage(notSuitablePercentage) },
  ].filter(({ value }) => value > 0);
};

export const AD_BANNERS = {
  LEAD: 'AM-AD-BANNER-LEAD',
  APPLICANT: 'AM-AD-BANNER-APPLICANT',
  LEAD_APPLICANT: 'AM-AD-BANNER-LEAD-APPLICANT',
};

export const AD_BANNERS_MESSAGE = {
  [AD_BANNERS.LEAD]:
    'Bist du daran interessiert zu erfahren, wie dich die A&M im Bereich der Mitarbeitergewinnung unterstützen kann?',
  [AD_BANNERS.APPLICANT]:
    'Bist du daran interessiert zu erfahren, wie dich die A&M im Bereich der Neukundengewinnung unterstützen kann?',
  [AD_BANNERS.LEAD_APPLICANT]:
    'Bist du daran interessiert zu erfahren, wie dich die A&M im Bereich der Systematisierung unterstützen kann?',
};

export const AD_BANNERS_SLACK_MESSAGE = {
  [AD_BANNERS.LEAD]: 'Ein A&M Kunde hat Interesse an Mitarbeitergewinnung über ContactCloud Anzeige gemeldet:',
  [AD_BANNERS.APPLICANT]: 'Ein A&M Kunde hat Interesse an Neukundengewinnung über ContactCloud Anzeige gemeldet:',
  [AD_BANNERS.LEAD_APPLICANT]: 'Ein A&M Kunde hat Interesse an Systematisierung über ContactCloud Anzeige gemeldet:',
};

export const CONTACT_TYPE = {
  AD: 'AD',
  APPLICANT: 'APPLICANT',
};

export const SORTING_ORDER = {
  ASC: 'ascending',
  DSC: 'descending',
};


export const EMAIL_INTEGRATIONS = {
  GMAIL: "GMAIL",
  OUTLOOK: "OUTLOOK",
  SMTP: "SMTP"
}

export const EMAIL_SETTINGS_TEMPLATE_TYPE = {
  EMAIL: "EMAIL",
  SIGNATURE: "SIGNATURE",
};


export const EMAIL_SETTINGS_AUTOMATION = {
  stays: "STAYS",
  moved: "MOVED",
  timeMapping: {
    "H": "Hour",
    "D": "Day",
    "M": "Minute",
    "W": "Week"
  },
  stageMapping: {
    new: 'New',
    contact_attempt: 'Contact Attempt',
    appointment_failed: 'Appointment Failed',
    appointment_agreed: 'Appointment Agreed',
    appointment_is_done: 'Appointment Is Done',
    offer_sent: 'Offer Sent',
    assignment: 'Assignment',
    not_suitable: 'Not Suitable',
    archived: 'Archived',
    all: 'All',
    deleted: 'Deleted'
  },
  Then: {
    MAIL: "Send an Email",
    SMS: "Send an SMS",
    MOVE: "Move to another stage"
  },
};